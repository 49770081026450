<template>
  <div>
    <v-snackbar
      v-model="snackBar.showSnackBar"
      color="#427D2D"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ snackBar.msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="closeSnackBar">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: ["msg", "showSnackBar"],
  data() {
    return {
      timeout: 5000,
    };
  },
  computed: {
    snackBar: {
      get: function () {
        return {
          showSnackBar: this.showSnackBar,
          msg: this.msg,
        };
      },
      set: function (item) {
        this.showSnackBar = item.showSnackBar;
        this.msg = item.msg;
      },
    },
  },
  methods: {
    closeSnackBar() {
      this.mySnackBar = false;
      this.$emit("stepper", {
        showSnackBar: false,
      });
    },
  },
};
</script>